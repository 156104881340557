<script>
import { mapState } from "vuex";
import RepositoryItem from "./RepositoryItem.vue";
import { repositoryMixins } from "../../../mixins/repository.js";
import { folderPathCacheMixins } from "../../../mixins/folderPathCache.js";
import { notificationMixins } from "../../../mixins/notification.js";
import Popup from "../../common/Popup.vue";
import Pagination from "../../common/Pagination";
import ResultsFilter from "../../common/ResultsFilter.vue";

export default {
  mixins: [repositoryMixins, folderPathCacheMixins, notificationMixins],
  data() {
    return {
      page: 0,
      pageSize: 25,
      currentSort: 'label',
      currentSortDir: 'asc',
      newName: "",
      newDescription: "",
      isAddNew: false,
      filter: "",
    };
  },
  components: {
    RepositoryItem,
    Popup,
    Pagination,
    ResultsFilter,
  },
  asyncComputed: {
    repositories() {
      return this.getRepositories({
        page: this.page,
        pageSize: this.pageSize,
        sort: this.currentSort + ',' + this.currentSortDir,
        // loader MUST NOT be used here, as the async watching behaviour causes an infinite loop
        // See https://github.com/foxbenjaminfox/vue-async-computed/issues/108
        showLoader: false,
        filter: this.filter,
      });
    }
  },
  computed: {
    ...mapState({
      isAdmin: (state) => state.auth.userData?.isAdmin ?? false,
    }),
    pages() {
      if (!this.repositories) {
        return 0;
      }
      return Math.ceil(this.repositories.totalItemCount / this.pageSize);
    },
    sortIcon(){
      if (this.currentSortDir === 'asc') { return "arrow_downward";}
      else return "arrow_upward";
    }
  },
  methods: {
    setPage(i) {
      this.page = i;
    },
    setPageSize(newPageSize) {
      this.pageSize = newPageSize;
    },
    setFilter(newFilter) {
      this.page = 0;
      this.filter = newFilter;
    },
    refresh() {
      this.$asyncComputed.repositories.update();
    },
    repositorySelected(item) {
      this.cacheRepositoryBasePath(item);
      this.$router.push({ name: "browse", params: { id: item.baseFolder.id } });
    },
    addRepositoryHandler() {
      this.isAddNew = true;
      this.newName = "";
      this.newDescription = "";
    },
    async addRepositoryConfirmed() {
      this.isAddNew = false;
      await this.createRepository(this.newName, this.newDescription);
      this.showSuccess(this.$t("repository-created"));
      await this.refresh();
    },
    sort:function(s) {
      this.currentSortDir = this.currentSortDir==='asc'?'desc':'asc';
      this.currentSort=s;
    },
    hasRecords() {
      return this.repositories && 
        this.repositories.length;
    }
  },
};
</script>

<template>
  <div class="repositories">
    <portal to="actions">
      <ResultsFilter @filterChange="setFilter"/>
      <button class="primary" @click.prevent="addRepositoryHandler" v-if="isAdmin">
        <m-icon icon="create_new_folder" />
        {{ $t("new-repository-button") }}
      </button>
    </portal>

    <Popup v-if="isAddNew" :valid="newName.length >= 2" @cancelled="isAddNew = false" @confirmed="addRepositoryConfirmed">
      <template v-slot:header>{{ $t("new-repository-title") }}</template>
      <template v-slot:body>
        <m-text-field v-model="newName" outlined required>
          <m-floating-label>{{ $t("new-repository-name-placeholder") }}</m-floating-label>
        </m-text-field>
        <m-text-field v-model="newDescription" outlined>
          <m-floating-label>{{ $t("new-repository-description-placeholder") }}</m-floating-label>
        </m-text-field>
      </template>
    </Popup>

    <div v-if="$asyncComputed.repositories.updating" class="loading"></div>
    <div v-else-if="$asyncComputed.repositories.error" class="load-error">Failed to load folder contents</div>
    <div v-else-if="!repositories || repositories.repository.length === 0">
      You do not own or steward any repositories
    </div>
    <div v-else-if="repositories && repositories.repository">
      <table class="listing">
        <thead>
          <tr>
            <th class="icon"></th>
            <th class="name" @click="sort('label')">Name
              <span>
                <m-icon v-bind:icon="sortIcon" class="sort-icon" />
              </span>
            </th>
            <th class="actions">Actions</th>
          </tr>
        </thead>
        <RepositoryItem v-for="item in repositories.repository" :key="item.id" :item="item" @modified="refresh" @selected="repositorySelected" />
      </table>
      <Pagination v-if="repositories && repositories.repository" :page="page" :pages="pages" :pageSize="pageSize" :totalRecords="repositories.totalItemCount" @pageChange="setPage" @pageSizeChange="setPageSize"/>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.loading, .load-error {
  text-align: center;
  margin-top: 4rem;
}

 .sort-icon {
   font-size: 14px;
 }

.listing {
  width: 100%;
  font-size: 0.8rem;

  th {
    font-weight: bold;
    color: #5B5B5B;
  }

  th.icon, th.actions, th.options {
    width: 1px;
  }

  ::v-deep tbody {
    background: white;

    td {
      border-bottom: 1px solid #EBEBEB;
    }
  }

  th, ::v-deep td {
    padding: 1rem 0.75rem;

    &:first-child {
      padding-left: 1rem;
    }
    &:last-child {
      padding-right: 1.5rem;
    }
  }
}
</style>
