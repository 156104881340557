<script>
import Shared from "./Shared.vue";

export default {
  components: {
    Shared,
  },
  mounted() {
    this.$store.dispatch('setBreadcrumbs', [{ to: { name: 'shared' }, label: this.$t('shared') }]);
  },
};
</script>

<template>
  <Shared />
</template>
