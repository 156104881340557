<script>
import Repositories from "./Repositories.vue";

export default {
  components: {
    Repositories,
  },
  mounted() {
    this.$store.dispatch('setBreadcrumbs', [{ to: { name: 'repositories' }, label: this.$t('repositories') }]);
  },
};
</script>

<template>
  <Repositories />
</template>
