<script>
import Shared from "./All.vue";

export default {
  components: {
    Shared,
  },
  mounted() {
    this.$store.dispatch('setBreadcrumbs', [{ to: { name: 'all' }, label: this.$t('nav.all') }]);
  },
};
</script>

<template>
  <Shared />
</template>
