<script>
import ItemSharing from "../sharing-popup/ItemSharing.vue";
import { folderMixins } from "../../../mixins/folder";
import { notificationMixins } from "../../../mixins/notification";
import {mapState} from "vuex";

export default {
  mixins: [folderMixins, notificationMixins],
  props: ["item"],
  components: {
    ItemSharing,
  },
  data() {
    return {
      isOptionsVisible: false,
      isSharing: false,
    };
  },
  computed: {
    ...mapState({
      isAdmin: (state) => state.auth.userData?.isAdmin ?? false,
    }),
  },
  methods: {
    selected() {
      this.$emit("selected", this.item);
    },
    toggleOptions() {
      this.isOptionsVisible = !this.isOptionsVisible;
    },
    sharingClosed(changed) {
      this.isSharing = false;
      if (changed) {
        this.$emit('modified');
      }
    },
  },
};
</script>

<template>
  <tbody>
    <tr class="item" @click.prevent="selected" :class="{ opened: isOptionsVisible }">
      <td class="icon">
        <m-icon icon="folder" />
      </td>
      <td class="name">
        <a href="#" @click.prevent.stop="selected">{{ item.name }}</a>
        <div class="tag-list" v-if="item.baseFolder.tags.length">
            <span>{{ item.baseFolder.tags.join(", ") }}</span>
          </div>
      </td>
      <td class="actions">
        <button @click.stop="isSharing = true" v-if="item.baseFolder.owner.includesMe || item.baseFolder.steward.includesMe || isAdmin">{{ $t("share") }}</button>

        <ItemSharing v-if="isSharing" :item="item.baseFolder" is-folder icon="folder" @closed="sharingClosed" />
      </td>

    </tr>
  </tbody>
</template>

<style lang="scss" scoped>
tr.item {
  cursor: pointer;

  .actions {
    text-align: right;
    white-space: nowrap;
  }

  .actions > * {
    visibility: hidden;
  }

  &:hover, &.opened {
    background: #EEEEEE;

    .actions > * {
      visibility: visible;
    }

    .options {
      color: #4F4F4F;
    }
  }

  .icon {
    color: #4F4F4F;
  }

  .options {
    color: #CACACA;

    &:hover {
      color: #4F4F4F;
    }
  }

  div.tag-list {
    margin-top: 0.5rem;
  }
  div.tag-list span {
    font-size: 0.7rem;
    font-style: italic;
  }
}

button {
  margin: -0.5rem 0 -0.5rem 1rem;
  cursor: pointer;
  font-size: 0.875rem;
  padding: 0.5rem 1rem;
  background: #DCDCDC;
}

tr.options-bar {
  background: #EBEBEB;
}
</style>
